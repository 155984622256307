import React from "react";

import "./text-input.scss";
import { FiSearch } from "react-icons/fi";

export const TextInput = ({ children, label, id, type, isSearch, ...props }) => {
  return (
    <div className="form-field">
      <div className="form-field__label-wrapper">
        <label htmlFor={id} className="form-field__label">
          {label}
        </label>
      </div>

      <div className="form-field__input-wrapper">
        <input
          type={type}
          id={id}
          className={`form-field__input${isSearch ? " form-field__input--has-search" : ""}`}
          {...props}
        />
        {isSearch && <FiSearch className="form-field__icon" />}
      </div>

      {children}
    </div>
  );
};
