import React, { useState } from "react";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import {
  Table,
  // TableCaption,
  TableThead,
  TableTbody,
  TableTr,
  TableTh,
  TableTd,
} from "../components/table/table";
import { Code } from "../components/code/code";
import { VisuallyHidden } from "../components/visually-hidden";
import { Spinner } from "../components/spinner/spinner";
import { TextInput } from "../components/text-input/text-input";
import {
  InfoList,
  InfoListItem,
  InfoListTerm,
  InfoListDefinition,
} from "../components/info-list/info-list";

import "./test-url.scss";

export default () => {
  const [url, setUrl] = useState("");
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = event => {
    event.preventDefault();

    setIsLoading(true);

    let fetchUrl = new URL("http://localhost:4000");
    fetchUrl.search = new URLSearchParams({
      url: url,
    });

    fetch(fetchUrl)
      .then(response => {
        return response.json();
      })
      .then(data => {
        setData(data);
        setIsLoading(false);
      })
      .catch(error => setError(error));
  };

  return (
    <Layout>
      <SEO title="Prøv din egen hjemmeside" />

      <form className="form" onSubmit={handleSubmit}>
        <TextInput
          label="Indtast hjemmside"
          id="get-data"
          onChange={event => setUrl(event.target.value)}
        />

        <button className="button">Hent data</button>
      </form>

      {data ? (
        <div className="test-url">
          <div className="test-url__top">
            <InfoList inline>
              <InfoListItem>
                <InfoListTerm>Side testet:</InfoListTerm>
                <InfoListDefinition>{data.pageUrl}</InfoListDefinition>
              </InfoListItem>

              <InfoListItem>
                <InfoListTerm>Antal fejl:</InfoListTerm>
                <InfoListDefinition>
                  {data.issues.length.toLocaleString("da-dk")}
                </InfoListDefinition>
              </InfoListItem>
            </InfoList>

            <a href={`http://localhost:4000${data.imageUrl}`} className="test-url__image">
              <img src={`http://localhost:4000${data.imageUrl}`} alt="" />
              <VisuallyHidden>Se stort billede at testet side</VisuallyHidden>
            </a>
          </div>

          <Table>
            <colgroup>
              <col className="col-number" />
              <col className="col-criteria" />
              <col />
              <col className="col-code" />
              <col className="col-code" />
            </colgroup>
            <TableThead>
              <TableTr>
                <TableTh>#</TableTh>
                <TableTh>Kriterie</TableTh>
                <TableTh>Beskrivelse af fejlen</TableTh>
                <TableTh>Kode hvori der er fejl</TableTh>
                <TableTh>Sti til fejlen</TableTh>
              </TableTr>
            </TableThead>

            <TableTbody>
              {data.issues.length > 0 ? (
                data.issues.map((item, nestedIndex) => {
                  const criteria = item.code.split(".")[3].replace(/_/g, ".");
                  return (
                    <TableTr key={`${criteria}-${nestedIndex}`}>
                      <TableTd thText="Nummer">{nestedIndex + 1}</TableTd>
                      <TableTd thText="Kriterie">{criteria}</TableTd>
                      <TableTd thText="Beskrivelse af fejlen" lang="en">
                        {item.message}
                      </TableTd>
                      <TableTd thText="Kode hvori der er fejl" lang="en">
                        <Code language="html">{item.context}</Code>
                      </TableTd>
                      <TableTd thText="Sti til fejlen" lang="en">
                        <Code language="css">{item.selector}</Code>
                      </TableTd>
                    </TableTr>
                  );
                })
              ) : (
                <TableTr>
                  <TableTd colSpan={5}>Ingen fejl – godt gået!</TableTd>
                </TableTr>
              )}
            </TableTbody>
          </Table>
        </div>
      ) : isLoading ? (
        <Spinner />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <p>Indtast url for at se resultater</p>
      )}
    </Layout>
  );
};
