import React from "react";
import classNames from "classnames";

import "./info-list.scss";

export const InfoList = ({ inline, ...props }) => (
  <dl className={classNames("info-list", { "info-list--inline": inline })} {...props} />
);
export const InfoListItem = props => <div className="info-list__item" {...props} />;
export const InfoListTerm = props => <div className="info-list__term" {...props} />;
export const InfoListDefinition = props => <div className="info-list__def" {...props} />;
